<template>
  <div class="overflow-hidden bg-white py-24 sm:py-32 bg-gray-100">
    <div class="mx-auto max-w-7xl px-6 lg:px-24">
      <h1 class="text-2xl font-bold my-4">
        What to expect during the transition
      </h1>
      <h4 class="text-lg font-medium my-4">
        We’ll do all of the heavy lifting to upgrade your account, but there are
        a few important dates and to-dos for you during this transition.
      </h4>
      <nav aria-label="Progress" class="w-full flex justify-center mt-8">
        <ol role="list" class="overflow-hidden">
          <li
            v-for="(step, stepIdx) in steps"
            :key="step.id"
            :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']"
          >
            <template v-if="step.status === 'complete'">
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-700"
                aria-hidden="true"
              />
              <!-- Changing all a tags to divs and removing href for now (:href="step.href")-->
              <div class="group relative flex items-start">
                <span class="flex h-9 items-center">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-700"
                  >
                    <img class="w-12" src="@/assets/images/check.svg" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 max-w-xl flex-col">
                  <span class="text-lg font-bold">{{ step.name }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
            <template v-else-if="step.status === 'current'">
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-700"
                aria-hidden="true"
              />
              <!-- Changing all a tags to divs and removing href for now (:href="step.href")-->
              <div class="group relative flex items-start" aria-current="step">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-700 bg-white"
                  >
                    <span class="h-2.5 w-2.5 rounded-full bg-primary-700" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 max-w-xl flex-col">
                  <span class="text-lg font-bold text-primary-700">{{
                    step.name
                  }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
            <template v-else>
              <div
                v-if="stepIdx !== steps.length - 1"
                class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-700"
                aria-hidden="true"
              />
              <!-- Changing all a tags to divs and removing href for now (:href="step.href")-->
              <div class="group relative flex items-start">
                <span class="flex h-9 items-center" aria-hidden="true">
                  <span
                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-700 bg-white"
                  >
                    <span class="h-2.5 w-2.5 rounded-full bg-transparent" />
                  </span>
                </span>
                <span class="ml-4 flex min-w-0 max-w-xl flex-col">
                  <span class="text-lg font-bold text-primary-700">{{
                    step.name
                  }}</span>
                  <span
                    v-html="step.description"
                    class="text-sm text-neutral-800"
                  ></span>
                </span>
              </div>
            </template>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>
<script>
// import { CheckIcon } from "@heroicons/vue/20/solid";
export default {
  data() {
    return {
      steps: [
        {
          name: "7/22/2024",
          description:
            "<span>Your upgraded HSA will be opened, and you will receive a new White Card HSA.</span><br><br><span><strong>What should I do with my Blue Card HSA?</strong></span><br><span>You can choose to either continue to use your Blue Card HSA until the funds are exhausted or you can transfer your funds to your new HSA. See the <a style='color: blue;' href='#13'>FAQ</a> below for more details on future fees that may be charged if funds are not exhausted, or on how to transfer your funds</span>",
          href: "#",
          status: this.checkDate("07/22/2024"),
          id: "0001"
        },
        {
          name: "7/26/2024",
          description:
            "<span>Your <a style='color: blue;' href='#10'>new payment card</a> will be mailed. It will be a white card with the RMR logo. Keep your eye on your mailbox and activate it right away so it’s ready when you need it!</span>",
          href: "#",
          status: this.checkDate("07/26/2024"),
          id: "0005"
        },
        {
          name: "7/31/2024",
          description:
            "<span>If you choose to transfer your Blue Card HSA to your New White Card HSA, your HSA investments will need to be liquidated prior to any transfer. Please liquidate your investments by this date. <a style='color: blue;' href='#12'>Here’s how</a>.</span><br/><br/><span>Last day to use your RMR Blue Card for FSA, HRA, or Parking/Transit expenses (Your card will remain active to use HSA funds).</span><br/><br/><span>Last day to submit claims for reimbursement to your previous account. We recommend you save your claim history from the old portal, because previous claim history will not be visible on the new portal.</span>",
          href: "#",
          status: this.checkDate("07/31/2024"),
          id: "0002"
        },
        {
          name: "8/1/2024 - 8/15/2024",
          description:
            "<span>The <a style='color: blue;' href='#15'>Quiet Period</a> begins. All existing benefit type funds will be unavailable while they are transferring to your new account. All new contributions will be added to the new portal.</span>",
          href: "#",
          status: this.checkDate("08/01/2024"),
          id: "0006"
        },
        {
          name: "8/15/2024",
          description:
            "<span>The Quiet Period is expected to end. Sign in to your new account using the new RMR app (<strong>RMR Benefits</strong>). Don’t forget to download the new RMR app and begin using your new card. </span><a style='color:blue;' target='_blank' rel='noreferrer' href='https://apps.apple.com/us/app/rmr-benefits/id6479498415'>App Store</a><span> - </span><a style='color:blue;' target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=com.rockymountainreserve.rmr_app&hl=en_US'>Google Play</a>",
          href: "#",
          status: this.checkDate("08/15/2024"),
          id: "0007"
        }
      ]
    };
  },
  methods: {
    checkDate(dateProp) {
      var newDate = new Date(dateProp.slice(0, 10));
      var today = new Date();
      var upcoming = new Date(newDate);
      upcoming.setDate(upcoming.getDate() - 6);
      if (today >= newDate) {
        return "complete";
      } else if (today >= upcoming) {
        return "current";
      } else if (newDate < today) {
        return "upcoming";
      }
    }
  }
};
</script>
